@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

*{
  margin: 0;
}

body{
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
}
