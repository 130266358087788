.display{
    display: grid;
    grid-template-columns: auto auto;
    width: fit-content;
}

@media only screen and (max-width:500px) {
    .display{
        display: block;
    }
}